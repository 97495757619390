import "./Bplus.scss";
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/400.css'

function BplusTemplate(props) {
    return (
        <div className={"page bplus"}>
            {props.children}
        </div>
    );
}

export default BplusTemplate;
